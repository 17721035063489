<template>
    <div class="edit-profile-detail">
        <StackRouterHeaderBar left-button="close" :title="title" :showTitle="true" />
        <div class="title" v-html="$translate(type.toUpperCase())" />
        <div class="section">
            <TextareaWithX v-model="content" :placeholder="placeholder" />
            <BottomButton @click="submit" :label="$translate('SAVE')" :disabled="disabled" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'EditProfileText',
    props: ['type', 'section'],
    computed: {
        profile() {
            return this.$store.getters.profile || []
        },
        placeholder() {
            if (this.type === 'family_intro')
                return '- 가족의 분위기, 성장 환경, 문화\n- 부모님의 학력, 직업\n- 결혼시 지원 가능한 부분, 노후대비, 상속 예정 내용 등을 입력해주세요.'
            if (this.type === 'finance')
                return '구체적으로 어떤 자산을 얼마나 갖고 있는지 적어주세요. 상속 예정인 내용을 적어주셔도 좋아요. (예금 등 현금성 자산, 주식, 부동산 등)'

            return '입력해주세요'
        },
        title() {
            return this.section.toUpperCase()
        },
        disabled() {
            return !this.content
        },
    },
    data: () => ({
        content: '',
    }),
    methods: {
        submit() {
            this.$set(this.profile, this.type, this.content)

            this.$stackRouter.pop()
        },
    },
    mounted() {
        this.content = this.profile[this.type] || ''
    },
}
</script>

<style scoped lang="scss">
.edit-profile-detail {
    //padding: 0 16px;
}
::v-deep .textarea-with-x {
    textarea {
        height: 250px;
        line-height: 24px;
    }
}
</style>
